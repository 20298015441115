import React, { useState, useEffect, useReducer, useRef, useContext } from "react";

import { isSameDay, parseISO, format } from "date-fns";
import clsx from "clsx";

import { green } from "@material-ui/core/colors";
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  makeStyles,
  Badge,
  Tooltip,
  Grid,
} from "@material-ui/core";

import {
  AccessTime,
  Block,
  Done,
  DoneAll,
  ExpandMore,
  GetApp,
  Launch,
  MicNone,
  Pause,
  PlayArrow,
  Reply,
  Speed,
  Facebook,
  Instagram,
  CloudDownload
} from "@material-ui/icons";
import ReactPlayer from 'react-player';
import MarkdownWrapper from "../MarkdownWrapper";
import ModalImageCors from "../ModalImageCors";
import MessageOptionsMenu from "../MessageOptionsMenu";
import whatsBackground from "../../assets/wa-background.png";
import LocationPreview from "../LocationPreview";
import whatsBackgroundDark from "../../assets/wa-background-dark.png"; //DARK MODE PLW DESIGN//
import VCardPreview from "../VCardPreview";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { SocketContext } from "../../context/Socket/SocketContext";
import { Dialog, DialogContent } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos, Close } from "@material-ui/icons";
import { ForwardMessageContext } from "../../context/ForwarMessage/ForwardMessageContext";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import SelectMessageCheckbox from "./SelectMessageCheckbox";
import ReactH5AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import LinearProgress from '@material-ui/core/LinearProgress';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import Typography from '@material-ui/core/Typography';
import SpeedIcon from '@material-ui/icons/Speed';
import MicIcon from '@material-ui/icons/Mic';
import { Fab } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import AudioModal from "../AudioModal";
import { Mutex } from "async-mutex";
import ImagePreview from "./ImagePreview";
const loadPageMutex = new Mutex();

const useStyles = makeStyles((theme) => ({
  textSlider: {
    color: theme.palette.sliderSeta, marginTop: 10, textAlign: 'center'
  },
  dialogContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    padding: theme.spacing(2),
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.default,
  },
  dialogTitle: {
    fontWeight: "bold",
  },
  dialogHeaderActions: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  closeButton: {
    color: theme.palette.error.main,
  },
  downloadButton: {
    color: theme.palette.primary.main,
  },
  navigationButton: {
    position: 'absolute',
    top: '50%',
    color: "#128c7e",
    zIndex: 10, // Garante que as setas fiquem sobre a imagem
    transform: 'translateY(-50%)', // Centraliza verticalmente
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Fundo escuro transparente nas setas
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 1)', // Fundo mais escuro ao passar o mouse
    },
  },
  prevButton: {
    left: 10,
  },
  nextButton: {
    right: 10,
  },
  messagesListWrapper: {
    overflow: "hidden",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    width: "100%",
    minWidth: 300,
    minHeight: 200,
    transition: 'background-color 0.5s ease-in-out',
  },
  forwardMessage: {
    fontSize: 12,
    fontStyle: "italic",
    position: "absolute",
    top: 0,
    left: 5,
    color: "#999",
    display: "flex",
    alignItems: "center"
  },
  messagesList: {
    backgroundImage: theme.mode === 'light' ? `url(${whatsBackground})` : `url(${whatsBackgroundDark})`, //DARK MODE PLW DESIGN//
    backgroundColor: theme.palette.chatBackground,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "20px 20px 20px 20px",
    overflowY: "scroll",
    ...theme.scrollbarStyles,
    overflowAnchor: 'none',
  },

  circleLoading: {
    color: green[500],
    position: "absolute",
    opacity: "70%",
    top: 0,
    left: "50%",
    marginTop: 12,
  },
  messageLeft: {
    marginRight: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: theme.mode === "light" ? "#ffffff" : "rgba(22, 31, 37, 1)", // Fundo adaptado para dark mode
    color: theme.mode === "light" ? "#303030" : "#f0f0f0", // Texto adaptado para dark mode
    alignSelf: "flex-start",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow:
      theme.mode === "light"
        ? "0 1px 1px #b3b3b3"
        : "0 1px 1px rgba(0, 0, 0, 0.5)", // Sombra adaptada para dark mode
  },

  messageLeftURL: {
    marginRight: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 400,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: theme.mode === "light" ? "#ffffff" : "rgba(22, 31, 37, 1)", // Fundo adaptado para dark mode
    color: theme.mode === "light" ? "#303030" : "#f0f0f0", // Texto adaptado para dark mode
    alignSelf: "flex-start",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow:
      theme.mode === "light"
        ? "0 1px 1px #b3b3b3"
        : "0 1px 1px rgba(0, 0, 0, 0.5)", // Sombra adaptada para dark mode
  },

  messageLeftImg: {
    marginRight: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 300,
    height: "auto",
    display: "block",
    position: "relative",
    backgroundColor: "transparent", // Remove o fundo branco adicional
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },


    whiteSpace: "pre-wrap",
    backgroundColor: theme.mode === "light" ? "#ffffff" : "rgba(22, 31, 37, 1)", // Fundo adaptado para dark mode
    color: theme.mode === "light" ? "#303030" : "#f0f0f0", // Texto adaptado para dark mode
    alignSelf: "flex-start",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    borderRadius: 8, // Arredonda todas as bordas
    padding: 0, // Remove padding adicional
    boxShadow: "none", // Remove sombras externas para evitar excesso de borda
  },


  quotedContainerLeft: {
    margin: "-3px -80px 6px -6px",
    overflow: "hidden",
    backgroundColor: "#f0f0f0",
    backgroundColor: theme.mode == 'light' ? "#f0f0f0" : "#121a1f",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
    cursor: "pointer",
  },

  quotedMsg: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  quotedSideColorLeft: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },


  messageRight: {
    marginLeft: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: theme.mode === "light" ? "#dcf8c6" : "#005c4b", // Fundo adaptado para dark mode
    color: theme.mode === "light" ? "#303030" : "#f0f0f0", // Texto adaptado para dark mode
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  messageRightURL: {
    marginLeft: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 400,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: theme.mode === "light" ? "#dcf8c6" : "#005c4b", // Fundo adaptado para dark mode
    color: theme.mode === "light" ? "#303030" : "#f0f0f0", // Texto adaptado para dark mode
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  messageRightImg: {
    marginLeft: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 300,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: theme.mode === "light" ? "#dcf8c6" : "#005c4b",
    color: theme.mode === "light" ? "#303030" : "#f0f0f0",
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: theme.mode === "light" ? "0 1px 1px #b3b3b3" : "0 1px 1px #000",
  },

  quotedContainerRight: {
    margin: "-3px -80px 6px -6px",
    overflowY: "hidden",
    backgroundColor: theme.mode == 'light' ? "#cfe9ba" : "#014a3d",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },


  quotedMsgRight: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    whiteSpace: "pre-wrap",
  },

  quotedSideColorRight: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },

  messageActionsButton: {
    display: "none",
    position: "relative",
    color: "#999",
    zIndex: 1,
    backgroundColor: "inherit",
    opacity: "90%",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "inherit" },
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },

  textContentItem: {
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },

  textContentItemEdited: {
    overflowWrap: "break-word",
    padding: "3px 120px 6px 6px",
  },

  textContentItemDeleted: {
    fontStyle: "italic",
    color: "rgba(0, 0, 0, 0.36)",
    overflowWrap: "break-word",
    padding: "3px 30px 6px 6px",
  },

  messageMedia: {
    objectFit: "contain", // Mantém a imagem inteira visível
    maxWidth: '100%',      // Garante que a imagem não exceda a largura do contêiner
    maxHeight: '300px',    // Define uma altura máxima apropriada
    minWidth: '150px',     // Define uma largura mínima para evitar que imagens pequenas fiquem muito pequenas
    minHeight: '150px',    // Define uma altura mínima
    width: 'auto',         // Ajusta a largura automaticamente
    height: 'auto',        // Ajusta a altura automaticamente
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    marginTop: theme.spacing(1),

    // Estilos responsivos para telas menores (como celulares)
    [theme.breakpoints.down('sm')]: {
      maxHeight: '200px',    // Reduz a altura máxima em telas menores
      minWidth: '120px',     // Ajusta a largura mínima para telas menores
      minHeight: '120px',    // Ajusta a altura mínima para telas menores
    },
  },

  timestamp: {
    fontSize: 11,
    position: "absolute",
    bottom: 0,
    right: 5,
    color: "#999",
  },

  dailyTimestamp: {
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    width: "110px",
    backgroundColor: "#e1f3fb",
    margin: "10px",
    borderRadius: "10px",
    boxShadow: "0 1px 1px #b3b3b3",
  },

  dailyTimestampText: {
    color: "#808888",
    padding: 8,
    alignSelf: "center",
    marginLeft: "0px",
  },

  ackIcons: {
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  deletedIcon: {
    fontSize: 18,
    verticalAlign: "middle",
    marginRight: 4,
  },

  ackDoneAllIcon: {
    color: green[500],
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  downloadMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "inherit",
    padding: 10,
  },

  audioPlayer: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    borderRadius: '8px',
    width: '100%',
    maxWidth: '600px',
    minWidth: '450px'
  },

  audioContainer: {
    display: 'flex',
    flexDirection: 'column', // Mude de 'row' para 'column'
    alignItems: 'center',
    padding: '10px',
    borderRadius: '8px',
    width: '100%',
    maxWidth: '380px',
    justifyContent: 'center', // Ajusta os itens ao centro verticalmente
    marginBottom: '10px',
    minWidth: '380px',
    backgroundColor: theme.mode === "light" ? "#e5ddd5" : "rgba(22, 31, 37, 1)", // Cor de fundo leve para destacar a área de botões
  },
  audioActions: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
  },
  transcribeButton: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  transcription: {
    marginTop: '10px',
    fontStyle: 'italic',
    color: '#555',
    maxWidth: '100%',
    overflowWrap: 'break-word',
  },
  audioPlayer: {
    width: '100%',
    maxWidth: '500px',
    height: '50px',
  },

  imageContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  thumbnailsContainer: {
    position: 'absolute', // Posicionamento absoluto
    bottom: 30, // 30px acima do final do dialog
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
  },


  thumbnail: {
    height: '50px',
    width: '50px',
    margin: theme.spacing(0, 1),
    cursor: 'pointer',
    borderRadius: '4px',
    objectFit: 'cover',
    transition: 'border 0.3s ease, transform 0.3s ease',
    '&:hover': {
      border: `2px solid #000`,
      transform: 'scale(1.1)', // Efeito de zoom ao passar o mouse
    },
  },
  selectedThumbnail: {
    border: `2px solid green`,
  },
  dialogPaper: {
    position: 'relative', // Necessário para o posicionamento absoluto das thumbnails
    backgroundColor: "transparent",
    boxShadow: 'none',
    overflow: 'hidden',
    maxHeight: '100vh', // Limita a altura para a tela
  },
  downloadMediaGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    backgroundColor: theme.mode === "light" ? "#e5ddd5" : "rgba(22, 31, 37, 1)", // Cor de fundo leve para destacar a área de botões
    borderRadius: theme.shape.borderRadius, // Bordas arredondadas
  },
  button: {
    margin: theme.spacing(0.5), // Espaçamento entre botões
    color: '#fff', // Texto branco
    backgroundColor: '#25D366', // Verde WhatsApp
    '&:hover': {
      backgroundColor: '#1ebe57', // Verde um pouco mais escuro no hover
    },
  },
  linkButton: {
    textDecoration: 'none', // Remove o sublinhado dos links
  },
  audioPlayerContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    width: '100%',
    maxWidth: '600px',
    margin: '10px 0',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
  },
  progressContainer: {
    flex: 1,
    margin: theme.spacing(0, 2),
  },
  time: {
    minWidth: '50px',
    textAlign: 'center',
    fontSize: '0.75rem',
    color: theme.palette.text.secondary,
  },
  iconButton: {
    color: theme.palette.primary.main,
  },
  playbackRateButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(1),
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5),
    fontSize: '0.75rem',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  formatLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.75rem',
    color: theme.palette.primary.main,
    fontWeight: 500,
    marginLeft: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.light,
  },
  time: {
    minWidth: '50px',
    textAlign: 'right',
  },
  transcribeButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
  scrollToBottomButton: {
    position: 'fixed',
    bottom: theme.spacing(9),
    right: theme.spacing(2),
    zIndex: 1000,
  },
  hiddenPlayer: {
    opacity: 0,
    position: 'absolute',
    top: '-9999px',
    left: '-9999px',
  },
  '@global': {
    '@keyframes wave': {
      '0%, 60%, 100%': {
        transform: 'initial',
      },
      '30%': {
        transform: 'translateY(-15px)',
      },
    },
    '@keyframes quiet': {
      '25%': {
        transform: 'scaleY(.6)'
      },
      '50%': {
        transform: 'scaleY(.4)',
      },
      '75%': {
        transform: 'scaleY(.8)',
      }
    },
    '@keyframes normal': {
      '25%': {
        transform: 'scaleY(.1)'
      },
      '50%': {
        transform: 'scaleY(.4)',
      },
      '75%': {
        transform: 'scaleY(.6)',
      }
    },
    '@keyframes loud': {
      '25%': {
        transform: 'scaleY(1)'
      },
      '50%': {
        transform: 'scaleY(.4)',
      },
      '75%': {
        transform: 'scaleY(1.2)',
      }
    },
  },
  wave: {
    position: 'relative',
    textAlign: 'center',
    height: "30px",
    marginTop: "10px",
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  dot: {
    display: "inline-block",
    width: "7px",
    height: "7px",
    borderRadius: "50%",
    marginRight: "3px",
    background: theme.mode === 'light' ? "#303030" : "#ffffff",
    animation: "wave 1.3s linear infinite",
    "&:nth-child(2)": {
      animationDelay: "-1.1s",
    },
    "&:nth-child(3)": {
      animationDelay: "-0.9s",
    }
  },
  wavebarsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    height: "30px",
    marginTop: "5px",
    marginBottom: "5px",
    marginLeft: "auto",
    marginRight: "auto",
    "--boxSize": "5px",
    "--gutter": "4px",
    width: "calc((var(--boxSize) + var(--gutter)) * 5)",
  },
  wavebars: {
    transform: "scaleY(.4)",
    height: "100%",
    width: "var(--boxSize)",
    animationDuration: "1.2s",
    backgroundColor: theme.mode === 'light' ? "#303030" : "#ffffff",
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 'infinite',
    borderRadius: '8px',
  },
  wavebar1: {
    animationName: 'quiet'
  },
  wavebar2: {
    animationName: 'normal'
  },
  wavebar3: {
    animationName: 'quiet'
  },
  wavebar4: {
    animationName: 'loud'
  },
  wavebar5: {
    animationName: 'quiet'
  }
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_MESSAGES") {
    const messages = action.payload;
    const newMessages = [];

    messages.forEach((message) => {
      const messageIndex = state.findIndex((m) => m.id === message.id);
      if (messageIndex !== -1) {
        state[messageIndex] = message;
      } else {
        newMessages.push(message);
      }
    });

    return [...newMessages, ...state];
  }

  if (action.type === "ADD_MESSAGE") {
    const newMessage = action.payload;
    const messageIndex = state.findIndex((m) => m.id === newMessage.id);

    if (messageIndex !== -1) {
      state[messageIndex] = newMessage;
    } else {
      state.push(newMessage);
    }

    return [...state];
  }

  if (action.type === "UPDATE_MESSAGE") {
    const messageToUpdate = action.payload;
    const messageIndex = state.findIndex((m) => m.id === messageToUpdate.id);

    if (messageIndex !== -1) {
      state[messageIndex] = messageToUpdate;
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};


// Defina CustomAudioPlayer fora do MessagesList
const CustomAudioPlayer = React.memo(({ audioUrl, message, transcribeAudio, loadingTranscriptions }) => {
  const classes = useStyles();
  const [playing, setPlaying] = useState(false);
  const [played, setPlayed] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);
  const playerRef = useRef(null);

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const handleProgress = (state) => {
    setPlayed(state.played);
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const handleEnded = () => {
    setPlaying(false);
    setPlayed(0); // Reseta o progresso de reprodução
  };

  const changePlaybackRate = () => {
    const newRate = playbackRate === 1 ? 1.5 : 1;
    setPlaybackRate(newRate);
    if (playerRef.current) {
      playerRef.current.seekTo(played * duration, 'seconds');
    }
  };

  const formattedTime = (seconds) => new Date(seconds * 1000).toISOString().substr(14, 5);
  const formatTime = (seconds) => {
    if (isNaN(seconds)) return '00:00';
    const date = new Date(seconds * 1000);
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds();
    return `${mm < 10 ? '0' + mm : mm}:${ss < 10 ? '0' + ss : ss}`;
  };

  // Função para capturar a duração do áudio
  const handleDurationChange = (duration) => {
    setDuration(duration);
  };


  return (
    <div className={classes.audioPlayerContainer}>
      {/* Controles de Play/Pause */}
      <div className={classes.controls}>
        <Tooltip title={playing ? 'Pausar' : 'Reproduzir'}>
          <IconButton onClick={handlePlayPause} color="primary">
            {playing ? <Pause /> : <PlayArrow />}
          </IconButton>
        </Tooltip>
      </div>

      {/* Barra de Progresso */}
      <div className={classes.progressContainer}>
        <LinearProgress variant="determinate" value={played * 100} />
        <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '0.75rem', marginTop: '4px' }}>
          <Typography className={classes.time}>{formatTime(played * duration)}</Typography>
          <Typography className={classes.time}>{formatTime(duration)}</Typography>
        </div>
      </div>

      {/* Controles de Taxa de Reprodução e Transcrição */}
      <div className={classes.controls}>
        <Tooltip title="Alterar Taxa de Reprodução">
          <div className={classes.playbackRateButton} onClick={changePlaybackRate}>
            {playbackRate}x
          </div>
        </Tooltip>
        <Tooltip title="Transcrever Áudio">
          <IconButton
            onClick={() => transcribeAudio(message.id, audioUrl)}
            className={classes.transcribeButton}
            disabled={loadingTranscriptions[message.id]}
          >
            {loadingTranscriptions[message.id] ? <CircularProgress size={24} /> : <MicNone />}
          </IconButton>
        </Tooltip>
      </div>

      {/* ReactPlayer Oculto */}
      <ReactPlayer
        ref={playerRef}
        url={audioUrl}
        playing={playing}
        onProgress={handleProgress}
        onDuration={handleDurationChange}
        onEnded={handleEnded}
        playbackRate={playbackRate}
        controls={false}
        width="0"
        height="0"
        className={classes.hiddenPlayer}
      />
    </div>
  );
});


const MessagesList = ({ ticket, ticketId, isGroup }) => {
  const classes = useStyles();

  const [messagesList, dispatch] = useReducer(reducer, []);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const lastMessageRef = useRef();

  const [selectedMessage, setSelectedMessage] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const messageOptionsMenuOpen = Boolean(anchorEl);
  const currentTicketId = useRef(ticketId);
  const isIOS = false;// /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const socketManager = useContext(SocketContext);
  const { setReplyingMessage } = useContext(ReplyMessageContext);
  const { showSelectMessageCheckbox } = useContext(ForwardMessageContext);

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [imageList, setImageList] = useState([]);
  const [imageIndexMapping, setImageIndexMapping] = useState([]); // Armazena o mapeamento de índice
  const [openModal, setOpenModal] = useState(false);
  const [zoomed, setZoomed] = useState(false);
  const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
  const messageRefs = useRef({});
  const [transcriptions, setTranscriptions] = useState({});
  const [loadingTranscriptions, setLoadingTranscriptions] = useState({});
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);
  //const [lastImageLoaded, setLastImageLoaded] = useState(false);
  const [contactPresence, setContactPresence] = useState({ status: "available", ticketId: 0 });
  //const [loadedImages, setLoadedImages] = useState(new Set()); // Rastreia URLs das imagens carregadas
  //const [pendingImages, setPendingImages] = useState(0);
  //const [isLoadingOldMessages, setIsLoadingOldMessages] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(0);


  useEffect(() => {
    const images = [];
    const mapping = [];

    messagesList.forEach((message, index) => {
      if (message.mediaType === "image") {
        images.push(message.mediaUrl);
        mapping.push(index); // Mapeia o índice da mensagem na lista completa
      }
    });

    setImageList(images);
    setImageIndexMapping(mapping); // Define o mapeamento de índices
  }, [messagesList]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft') {
        navigateImage('prev');
      } else if (event.key === 'ArrowRight') {
        navigateImage('next');
      }
    };

    if (openModal) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [openModal]); // Dependências incluem o estado do modal e a função de navegação


  const handleImageClick = (imageIndex, e) => {
    if (e) {
      const rect = e.target.getBoundingClientRect();
      const x = ((e.clientX - rect.left) / rect.width) * 100; // Posição X em percentual
      const y = ((e.clientY - rect.top) / rect.height) * 100; // Posição Y em percentual

      setZoomPosition({ x, y });
      setZoomed((prev) => !prev); // Alterna o estado de zoom
    } else {
      setSelectedImageIndex(imageIndex);
      setOpenModal(true); // Abre o modal
      setZoomed(false); // Reseta o zoom ao abrir o modal
    }
  };





  const handleCloseModal = () => {
    setOpenModal(false);
  };



  function loadData(incrementPage = false) {
    setLoading(true);
    const thisPageNumber = incrementPage ? pageNumber + 1 : 1;
    const delayDebounceFn = setTimeout(() => {
      const fetchMessages = async () => {
        if (ticketId === undefined) return;
        try {
          const { data } = await api.get("/messages/" + ticketId, {
            params: { pageNumber: thisPageNumber },
          });

          if (currentTicketId.current === ticketId) {
            console.log({ type: "LOAD_MESSAGES", payload: data.messages });
            dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
            setHasMore(data.hasMore);
            setLoading(false);
          }



          if (thisPageNumber === 1 && data.messages.length > 1) {
            // scrollToBottom();

            // setTimeout(() => {
            //scrollToBottom();
            //}, 500);
          }

        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchMessages();
      setPageNumber(thisPageNumber);
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }

  useEffect(async () => {
    dispatch({ type: "RESET" });
    setPageNumber(1);

    setSelectedImageIndex(0);
    setImageList([]);
    setImageIndexMapping([]);
    setOpenModal(false);
    setImagesLoaded(0);
    setContactPresence({ status: "available", ticketId: 0 });

    currentTicketId.current = ticketId;

    await loadPageMutex.runExclusive(async () => {
      loadData();
    });
  }, [ticketId]);

  useEffect(() => {

    //console.log({ selectedImageIndex, imageList });
  }, [selectedImageIndex]);


  useEffect(() => {

    const totalImages = messagesList.filter((m) => m.mediaType === "image").length;

    console.log({
      capo: true,
      imagesLoaded,
      totalImages,
      messagesList,
      cond: imagesLoaded === totalImages
    });

    if (imagesLoaded === totalImages) {


      scrollToBottom();

    }
  }, [imagesLoaded, messagesList]);



  useEffect(() => {
    // Extraindo todas as URLs de imagens da lista de mensagens
    const images = messagesList
      .filter((message) => message.mediaType === "image")
      .map((message) => message.mediaUrl);


    //console.log({ images });

    setImageList(images);

    // Verificando se o índice selecionado está dentro do intervalo válido
    if (selectedImageIndex >= images.length) {
      setSelectedImageIndex(0); // Reseta o índice se estiver fora do intervalo
    }
  }, [messagesList]);

  /*useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchMessages = async () => {
        if (ticketId === undefined) return;
        try {
          const { data } = await api.get("/messages/" + ticketId, {
            params: { pageNumber },
          });

          if (currentTicketId.current === ticketId) {
            dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
            setHasMore(data.hasMore);
            setLoading(false);
          }

          if (pageNumber === 1 && data.messages.length > 1) {
            scrollToBottom();

            setTimeout(() => {
              scrollToBottom();
            }, 250);
          }
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchMessages();
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [pageNumber, ticketId]);*/



  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketManager.getSocket(companyId);

    socket.on("ready", () => socket.emit("joinChatBox", `${ticket.id}`));

    socket.on(`company-${companyId}-appMessage`, (data) => {

      console.log({ type: "appMessage", payload: data });

      if (data.action === "create" && data.message.ticketId === currentTicketId.current) {


        dispatch({ type: "ADD_MESSAGE", payload: data.message });

        scrollToBottom();

      }

      if (data.action === "update" && data.message.ticketId === currentTicketId.current) {
        dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
      }
    });

     socket.on(`company-${companyId}-presence`, (data) => {
 
 
       if (data?.presence) {
         const { presence } = data;

         console.log({presenceData: data});
         
         if(ticketId == data.ticketId){
          setContactPresence({
            status: presence,
            ticketId
          });
         }
 
   
       }
     });

    return () => {
      socket.disconnect();
    };
  }, [ticketId, ticket, socketManager]);


  const loadMore = async () => {
    //setIsLoadingOldMessages(true); 
    await loadPageMutex.runExclusive(async () => {
      await loadData(true); // Incrementa a página
    });

  };

  const scrollToBottom = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({});
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    const isAtBottom = scrollHeight - scrollTop - clientHeight < 50; // Ajuste o valor 50 conforme necessário

    setShowScrollToBottom(!isAtBottom);

    if (!hasMore) return;

    if (scrollTop === 0) {
      document.getElementById("messagesList").scrollTop = 1;
    }

    if (loading) {
      return;
    }

    if (scrollTop < 50) {
      loadMore();
    }
  };



  const hanldeReplyMessage = (e, message) => {
    //if (ticket.status === "open" || ticket.status === "group") {
    setAnchorEl(null);
    setReplyingMessage(message);
    //}
  };

  const handleOpenMessageOptionsMenu = (e, message) => {
    setAnchorEl(e.currentTarget);
    setSelectedMessage(message);
  };

  const handleCloseMessageOptionsMenu = (e) => {
    setAnchorEl(null);
  };



  // Função para transcrever o áudio
  const transcribeAudio = async (messageId, audioUrl) => {
    setLoadingTranscriptions((prevState) => ({
      ...prevState,
      [messageId]: true
    }));
    try {
      const response = await fetch('https://api.rodticket.com/transcribe', {
        method: 'POST',
        body: JSON.stringify({ audioUrl, messageId }),  // Enviando o ID da mensagem
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setTranscriptions((prevState) => ({
        ...prevState,
        [messageId]: data.transcription
      }));
    } catch (error) {
      console.error('Error transcribing audio:', error);
    } finally {
      setLoadingTranscriptions((prevState) => ({
        ...prevState,
        [messageId]: false
      }));
    }
  };



  const handleDownload = async (url) => {
    try {
      const response = await fetch(url); // Busca o arquivo
      const data = await response.blob(); // Cria um blob a partir da resposta
      const downloadUrl = window.URL.createObjectURL(data); // Cria uma URL para o blob

      // Extrai o nome do arquivo e ajusta para remover o timestamp antes de "_"
      const fullFileName = url.split('/').pop(); // Exemplo: "timestamp_file.txt"
      const fileName = fullFileName.includes('_')
        ? fullFileName.split('_').slice(1).join('_') // Mantém tudo após o primeiro "_"
        : fullFileName; // Caso não tenha "_", usa o nome completo

      // Cria o link para download
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', fileName); // Define o nome do arquivo ajustado
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl); // Limpa a URL do objeto
    } catch (error) {
      console.error('Failed to download file:', error); // Tratamento de erro
    }
  };


  const checkMessageMedia = (message, index) => {

    console.log({
      mediaType: message.mediaType,
      body: message.body.includes("BEGIN:VCARD")
    });


    if (message.mediaType === "locationMessage" && message.body.split('|').length >= 2) {
      let locationParts = message.body.split('|')
      let imageLocation = locationParts[0]
      let linkLocation = locationParts[1]

      let descriptionLocation = null

      if (locationParts.length > 2)
        descriptionLocation = message.body.split('|')[2]

      return <LocationPreview image={imageLocation} link={linkLocation} description={descriptionLocation} />
    }
    else
      if (message.mediaType === "contactMessage" || message.body.includes("BEGIN:VCARD")) {
        let array = message.body.split("\n");
        let obj = [];
        let contact = "";
        for (let index = 0; index < array.length; index++) {
          const v = array[index];
          let values = v.split(":");
          for (let ind = 0; ind < values.length; ind++) {
            if (values[ind].indexOf("+") !== -1) {
              obj.push({ number: values[ind] });
            }
            if (values[ind].indexOf("FN") !== -1) {
              contact = values[ind + 1];
            }
          }
        }
        //console.log(array);
        //console.log(contact);
        //console.log(obj[0].number);
        return (
          <VCardPreview
            contact={contact}
            numbers={obj && obj[0] && obj[0].number ? obj[0].number : "Número indisponível"}
          />
        );

      }
      /* else if (message.mediaType === "vcard") {
        let array = message.body.split("\n");
        let obj = [];
        let contact = "";
        for (let index = 0; index < array.length; index++) {
          const v = array[index];
          let values = v.split(":");
          for (let ind = 0; ind < values.length; ind++) {
            if (values[ind].indexOf("+") !== -1) {
              obj.push({ number: values[ind] });
            }
            if (values[ind].indexOf("FN") !== -1) {
              contact = values[ind + 1];
            }
          }
        }
        return <VcardPreview contact={contact} numbers={obj[0].number} />
      } */
      /*else if (message.mediaType === "multi_vcard") {
        console.log("multi_vcard")
        console.log(message)
        
        if(message.body !== null && message.body !== "") {
          let newBody = JSON.parse(message.body)
          return (
            <>
              {
              newBody.map(v => (
                <VcardPreview contact={v.name} numbers={v.number} />
              ))
              }
            </>
          )
        } else return (<></>)
      }*/
      else if (message.mediaType === "image") {
        const imageIndex = imageList.indexOf(message.mediaUrl);
        const isLastImage = index === messagesList.length - 1;

        return (
          <ImagePreview
            message={message}
            handleImageClick={handleImageClick}
            imageIndex={imageIndex}
            onImageLoad={() => setImagesLoaded((prev) => prev + 1)}
          />

        );
      } else if (message.mediaType === "audio2") {
        return (
          <AudioModal url={message.mediaUrl} />
          // <audio controls>
          //   <source src={message.mediaUrl} type="audio/ogg"></source>
          //   {/* <source src={message.mediaUrl} type="audio/mp3"></source> */}
          // </audio>
        );
      }
      else if (message.mediaType === "audio") {

        // Verifica se é iOS e ajusta o tipo de arquivo de áudio
        if (isIOS) {
          message.mediaUrl = message.mediaUrl.replace("ogg", "mp3");
        }

        return (
          <div className={classes.audioContainer}>
            {/* Player de Áudio customizado */}
            <CustomAudioPlayer
              audioUrl={message.mediaUrl}
              message={message}
              transcribeAudio={transcribeAudio}
              loadingTranscriptions={loadingTranscriptions}
            />

            {/* Verifica se a transcrição já está no banco de dados */}
            {message.audioTranscription ? (

              <div className={classes.transcription}>
                <p><strong>Transcrição:</strong> {message.audioTranscription}</p>
              </div>
            ) : null}

            {/* Exibe a transcrição após ser obtida, se foi feita agora */}
            {transcriptions[message.id] && (
              <div className={classes.transcription}>
                <p><strong>Transcrição:</strong> {transcriptions[message.id]}</p>
              </div>
            )}
          </div>
        );
      }


      else if (message.mediaType === "video") {
        return (
          <video
            className={classes.messageMedia}
            src={message.mediaUrl}
            controls
          />
        );
      } else {
        return (
          <>
            <div className={classes.downloadMediaGrid}>
              <Grid container spacing={1} justifyContent="center">
                {/* Botão Abrir */}
                <Grid item>
                  <Button
                    startIcon={<Launch />} // Ícone de 'Abrir'
                    className={classes.button}
                    href={message.mediaUrl} // Link direto para o arquivo
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Abrir
                  </Button>
                </Grid>

                {/* Botão Download */}
                <Grid item>
                  <Button
                    startIcon={<GetApp />}
                    className={classes.button}
                    onClick={() => handleDownload(message.mediaUrl)}
                  >
                    Baixar
                  </Button>
                </Grid>
              </Grid>
            </div>
            <Divider />
          </>
        );
      }
  };


  /*
    const renderMessageAck = (message) => {
      if (message.ack === 1) {
        return <AccessTime fontSize="small" className={classes.ackIcons} />;
      }
      if (message.ack === 2) {
        return <Done fontSize="small" className={classes.ackIcons} />;
      }
      if (message.ack === 3) {
        return <DoneAll fontSize="small" className={classes.ackIcons} />;
      }
      if (message.ack === 4 || message.ack === 5) {
        return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
      }
    };
    */

  const renderMessageAck = (message) => {
    if (message.ack === 0) {
      return <AccessTime fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 1) {
      return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 2) {
      return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 3) {
      return <DoneAll fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 4 || message.ack === 5) {
      return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} style={{ color: '#0377FC' }} />;
    }
  };

  const renderDailyTimestamps = (message, index) => {
    if (index === 0) {
      return (
        <span
          className={classes.dailyTimestamp}
          key={`timestamp-${message.id}`}
        >
          <div className={classes.dailyTimestampText}>
            {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
          </div>
        </span>
      );
    }
    if (index < messagesList.length - 1) {
      let messageDay = parseISO(messagesList[index].createdAt);
      let previousMessageDay = parseISO(messagesList[index - 1].createdAt);

      if (!isSameDay(messageDay, previousMessageDay)) {
        return (
          <span
            className={classes.dailyTimestamp}
            key={`timestamp-${message.id}`}
          >
            <div className={classes.dailyTimestampText}>
              {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
            </div>
          </span>
        );
      }
    }
    if (index === messagesList.length - 1) {
      return (
        <div
          key={`ref-${message.createdAt}`}
          ref={lastMessageRef}
          style={{ float: "left", clear: "both" }}
        />
      );
    }
  };

  const renderNumberTicket = (message, index) => {
    if (index < messagesList.length && index > 0) {

      let messageTicket = message.ticketId;
      let connectionName = message.ticket?.whatsapp?.name;
      let previousMessageTicket = messagesList[index - 1].ticketId;

      if (messageTicket !== previousMessageTicket) {
        return (
          <center>
            <div className={classes.ticketNunberClosed}>
              Conversa encerrada: {format(parseISO(messagesList[index - 1].createdAt), "dd/MM/yyyy HH:mm:ss")}
            </div>

            <div className={classes.ticketNunberOpen}>
              Conversa iniciada: {format(parseISO(message.createdAt), "dd/MM/yyyy HH:mm:ss")}
            </div>
          </center>
        );
      }
    }
  };

  const renderMessageDivider = (message, index) => {
    if (index < messagesList.length && index > 0) {
      let messageUser = messagesList[index].fromMe;
      let previousMessageUser = messagesList[index - 1].fromMe;

      if (messageUser !== previousMessageUser) {
        return (
          <span style={{ marginTop: 16 }} key={`divider-${message.id}`}></span>
        );
      }
    }
  };

  const scrollToOriginalMessage = (messageId) => {

    //console.log({ messageRefs });


    const originalMessageElement = messageRefs.current[messageId];
    if (originalMessageElement) {
      originalMessageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };


  const renderQuotedMessage = (message) => {
    return (
      <div
        className={clsx(classes.quotedContainerLeft, {
          [classes.quotedContainerRight]: message.fromMe,
        })}
        onClick={() => scrollToOriginalMessage(message.quotedMsg?.id)} // Adiciona a ação de clicar para rolar

      >
        <span
          className={clsx(classes.quotedSideColorLeft, {
            [classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
          })}
        ></span>
        <div className={classes.quotedMsg}>
          {!message.quotedMsg?.fromMe && (
            <span className={classes.messageContactName}>
              {message.quotedMsg?.contact?.name}
            </span>
          )}

          {message.quotedMsg.mediaType === "audio"
            && (
              <div className={classes.downloadMedia}>
                <audio controls>
                  <source src={message.quotedMsg.mediaUrl} type="audio/ogg"></source>
                </audio>
              </div>
            )
          }
          {message.quotedMsg.mediaType === "video"
            && (
              <video
                className={classes.messageMedia}
                src={message.quotedMsg.mediaUrl}
                controls
              />
            )
          }
          {message.quotedMsg.mediaType === "application"
            && (
              <div className={classes.downloadMedia}>
                <Button
                  startIcon={<GetApp />}
                  color="primary"
                  variant="outlined"
                  target="_blank"
                  href={message.quotedMsg.mediaUrl}
                >
                  Download
                </Button>
              </div>
            )
          }

          {message.quotedMsg.mediaType === "image" ? (
            (() => {
              const imageIndex = imageList.indexOf(message.quotedMsg.mediaUrl); // Encontrar o índice da imagem
              // console.log({ imageIndex });

              return (
                <img
                  src={message.quotedMsg.mediaUrl}
                  alt="message"
                  className={classes.messageMedia}
                  onClick={() => handleImageClick(imageIndex)} // Usar o índice correto da imagem

                />
              );
            })()
          ) : (
            message.quotedMsg?.body
          )}


        </div>
      </div>
    );
  };

  const renderProtocolChanges = (message, index, test) => {

    if (index > 0) {
      const currentProtocol = message.protocolo;
      const previousProtocol = messagesList[index - 1].protocolo;

      if (currentProtocol !== previousProtocol) {
        const protocolStartTime = new Date(message.createdAt).toLocaleString();

        return (
          <div style={{
            backgroundColor: '#f0f4ff', /* Fundo azul claro */
            color: '#2a4d7f',          /* Texto azul escuro */
            border: '1px solid #d9e3f0',
            borderRadius: '8px',
            padding: '10px',
            margin: '10px auto',
            textAlign: 'center',
            maxWidth: '80%',
            fontWeight: '600',         /* Texto semibold */
            fontSize: '14px'
          }}>
            <div>
              Novo protocolo iniciado: {currentProtocol}
            </div>
            <div style={{
              fontSize: '12px',
              fontWeight: '400',
              marginTop: '5px',
              color: '#6b7c96'
            }}>
              Data e Hora de Início: {protocolStartTime}
            </div>
          </div>
        );
      }
    }
    return null;
  };


  const renderMessages = () => {
    if (messagesList.length > 0) {
      const viewMessagesList = messagesList.map((message, index) => {

        if (message.mediaType === "call_log") {
          return (
            <React.Fragment key={message.id}>
              {renderDailyTimestamps(message, index)}
              {renderNumberTicket(message, index)}
              {renderProtocolChanges(message, index, 1)} {/* Aqui adicionamos a chamada */}
              {renderMessageDivider(message, index)}

              <div className={classes.messageCenter}>
                <IconButton
                  variant="contained"
                  size="small"
                  id="messageActionsButton"
                  disabled={message.isDeleted}
                  className={classes.messageActionsButton}
                  onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                >
                  <ExpandMore />
                </IconButton>
                {isGroup && (
                  <span className={classes.messageContactName}>
                    {message.contact?.name}
                  </span>
                )}
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 17" width="20" height="17">
                    <path fill="#df3333" d="M18.2 12.1c-1.5-1.8-5-2.7-8.2-2.7s-6.7 1-8.2 2.7c-.7.8-.3 2.3.2 2.8.2.2.3.3.5.3 1.4 0 3.6-.7 3.6-.7.5-.2.8-.5.8-1v-1.3c.7-1.2 5.4-1.2 6.4-.1l.1.1v1.3c0 .2.1.4.2.6.1.2.3.3.5.4 0 0 2.2.7 3.6.7.2 0 1.4-2 .5-3.1zM5.4 3.2l4.7 4.6 5.8-5.7-.9-.8L10.1 6 6.4 2.3h2.5V1H4.1v4.8h1.3V3.2z"></path>
                  </svg> <span>Chamada de voz/vídeo perdida às {format(parseISO(message.createdAt), "HH:mm")}</span>
                </div>
              </div>
            </React.Fragment>
          );
        }

        //        console.log({message});

        if (!message.fromMe) {

          let classeCerta = message?.mediaType == 'image' ? classes.messageLeftImg : (message?.mediaUrl ? classes.messageLeftURL : classes.messageLeft);


          return (
            <React.Fragment key={message.id}>
              {renderDailyTimestamps(message, index)}
              {renderNumberTicket(message, index)}
              {renderProtocolChanges(message, index, 2)} {/* Aqui adicionamos a chamada */}
              {renderMessageDivider(message, index)}
              <div
                className={classeCerta}
                title={message.queueId && message.queue?.name}
                ref={el => messageRefs.current[message.id] = el}
              //onDoubleClick={(e) => hanldeReplyMessage(e, message)}
              >
                {showSelectMessageCheckbox && (
                  <SelectMessageCheckbox
                    // showSelectMessageCheckbox={showSelectMessageCheckbox}
                    message={message}
                  // selectedMessagesList={selectedMessagesList}
                  // setSelectedMessagesList={setSelectedMessagesList}
                  />
                )}
                <IconButton
                  variant="contained"
                  size="small"
                  id="messageActionsButton"
                  disabled={message.isDeleted}
                  className={classes.messageActionsButton}
                  onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                >
                  <ExpandMore />
                </IconButton>
                {message.isForwarded && (
                  <div>
                    <span className={classes.forwardMessage}
                    ><Reply style={{ color: "grey", transform: 'scaleX(-1)' }} /> Encaminhada
                    </span>
                    <br />
                  </div>
                )}
                {isGroup && (
                  <span className={classes.messageContactName}>
                    {message.contact?.name}
                  </span>
                )}

                {/* aviso de mensagem apagado pelo contato */}
                {message.isDeleted && (
                  <div>
                    <span className={"message-deleted"}
                    >Essa mensagem foi apagada pelo contato &nbsp;
                      <Block
                        color="error"
                        fontSize="small"
                        className={classes.deletedIcon}
                      />
                    </span>
                  </div>
                )}

                {(message.mediaUrl || message.mediaType === "locationMessage" || message.mediaType === "vcard" || message.mediaType === "contactMessage"
                  //|| message.mediaType === "multi_vcard" 
                ) && checkMessageMedia(message, index)}

                <div className={classes.textContentItem}>

                  {message.quotedMsg && renderQuotedMessage(message)}
                  {message.mediaType !== "reactionMessage" && (
                    <MarkdownWrapper>
                      {message.mediaType === "locationMessage" || message.mediaType === "contactMessage"
                        ? null
                        : message.body}
                    </MarkdownWrapper>
                  )}
                  {message.quotedMsg && message.mediaType === "reactionMessage" && message.body && (
                    <>
                      <span style={{ marginLeft: "0px", display: 'flex', alignItems: 'center' }}>
                        <MarkdownWrapper>
                          {"_*" + (message.fromMe ? 'Você' : (message?.contact?.name ?? 'Contato')) + "*_ reagiu com:   "}
                        </MarkdownWrapper>
                        <Badge
                          className={classes.badge}
                          overlap="circular"
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          badgeContent={
                            <span style={{ fontSize: "1.2em", marginTop: "0", marginLeft: "5px" }}>
                              {message.body}
                            </span>
                          }
                        >
                        </Badge>
                      </span>
                    </>
                  )}

                  <span className={classes.timestamp} style={{ userSelect: 'none' }}>
                    {message.isEdited ? "Editada " + format(parseISO(message.createdAt), "HH:mm") : format(parseISO(message.createdAt), "HH:mm")}
                  </span>
                </div>
              </div>
            </React.Fragment>
          );
        } else {

          let classeCertaOutras = [];
          classeCertaOutras['image'] = classes.messageRightImg;
          classeCertaOutras['contactMessage'] = classes.messageRight;
          classeCertaOutras['video'] = classes.messageRight;
          classeCertaOutras['locationMessage'] = classes.messageRight;

          //contactMessage vcard locationMessage


          let classeCertaOutra = message?.mediaType == 'image' ? classes.messageRightImg : (message?.mediaUrl ? classes.messageRightURL : classes.messageRight);

          return (
            <React.Fragment key={message.id}>
              {renderDailyTimestamps(message, index)}
              {renderNumberTicket(message, index)}
              {renderProtocolChanges(message, index, 3)} {/* Aqui adicionamos a chamada */}
              {renderMessageDivider(message, index)}
              <div className={classeCertaOutra}
                //onDoubleClick={(e) => hanldeReplyMessage(e, message)}
                ref={el => messageRefs.current[message.id] = el}
              >
                {showSelectMessageCheckbox && (
                  <SelectMessageCheckbox
                    // showSelectMessageCheckbox={showSelectMessageCheckbox}
                    message={message}
                  // selectedMessagesList={selectedMessagesList}
                  // setSelectedMessagesList={setSelectedMessagesList}
                  />
                )}
                <IconButton
                  variant="contained"
                  size="small"
                  id="messageActionsButton"
                  disabled={message.isDeleted}
                  className={classes.messageActionsButton}
                  onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                >
                  <ExpandMore />
                </IconButton>
                {message.isForwarded && (
                  <div>
                    <span className={classes.forwardMessage}
                    ><Reply style={{ color: "grey", transform: 'scaleX(-1)' }} /> Encaminhada
                    </span>
                    <br />
                  </div>
                )}
                {(message.mediaUrl || message.mediaType === "locationMessage" || message.mediaType === "vcard" || message.mediaType === "contactMessage"
                  //|| message.mediaType === "multi_vcard" 
                ) && checkMessageMedia(message, index)}
                <div
                  className={clsx(classes.textContentItem, {
                    [classes.textContentItemDeleted]: message.isDeleted,
                  })}
                >
                  {message.isDeleted && (
                    <Block
                      color="disabled"
                      fontSize="small"
                      className={classes.deletedIcon}
                    />
                  )}
                  {message.quotedMsg && renderQuotedMessage(message)}
                  {message.mediaType !== "reactionMessage" && message.mediaType !== "vcard" && message.mediaType !== "locationMessage" && (
                    <MarkdownWrapper>{message.body}</MarkdownWrapper>
                  )}
                  {message.quotedMsg && message.mediaType === "reactionMessage" && message.body && (
                    <>
                      <span style={{ marginLeft: "0px", display: 'flex', alignItems: 'center' }}>
                        <MarkdownWrapper>
                          {"_*" + (message.fromMe ? 'Você' : (message?.contact?.name ?? 'Contato')) + "*_ reagiu com:   "}
                        </MarkdownWrapper>
                        <Badge
                          className={classes.badge}
                          overlap="circular"
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          badgeContent={
                            <span style={{ fontSize: "1.2em", marginTop: "0", marginLeft: "5px" }}>
                              {message.body}
                            </span>
                          }
                        >
                        </Badge>
                      </span>
                    </>
                  )}
                  <span className={classes.timestamp} style={{ userSelect: 'none' }}>
                    {message.isEdited ? "Editada " + format(parseISO(message.createdAt), "HH:mm") : format(parseISO(message.createdAt), "HH:mm")}
                    {renderMessageAck(message)}
                  </span>
                </div>
              </div>
            </React.Fragment>
          );
        }
      });
      return viewMessagesList;
    } else {
      return <div>Diga olá para seu novo contato!</div>;
    }
  };

  const navigateImage = (direction) => {
    setSelectedImageIndex((prevIndex) => {
      let newIndex;

      if (typeof prevIndex !== 'number' || isNaN(prevIndex)) {
        newIndex = 0; // Redefine para o primeiro índice se o índice atual estiver inválido
      } else {
        if (direction === 'next') {
          newIndex = prevIndex >= imageList.length - 1 ? prevIndex : prevIndex + 1;
        } else if (direction === 'prev') {
          newIndex = prevIndex <= 0 ? 0 : prevIndex - 1;
        }
      }

      //  console.log(`Navigating to index: ${newIndex}`);
      return newIndex;
    });
  };

  useEffect(() => {
    if (
      (contactPresence.status === "composing" || contactPresence.status === "recording") &&
      ticket?.id === contactPresence.ticketId
    ) {
      scrollToBottom();
    }
  }, [contactPresence, ticket]);
  

  return (
    <div className={classes.messagesListWrapper}>
      <MessageOptionsMenu
        message={selectedMessage}
        anchorEl={anchorEl}
        menuOpen={messageOptionsMenuOpen}
        handleClose={handleCloseMessageOptionsMenu}
      />
      <div
        id="messagesList"
        className={classes.messagesList}
        onScroll={handleScroll}
      >
        {showScrollToBottom && (
          <Fab
            color="primary"
            size="small"
            onClick={scrollToBottom}
            className={classes.scrollToBottomButton}
          >
            <KeyboardArrowDown />
          </Fab>
        )}

        {messagesList.length > 0 ? renderMessages() : []}
        {contactPresence.status === "composing" && ticket?.id == contactPresence.ticketId && (
          <div className={classes.messageLeft}>
            <div className={classes.wave}>
              <span className={classes.dot}></span>
              <span className={classes.dot}></span>
              <span className={classes.dot}></span>
            </div>
          </div>
        )}
        {contactPresence.status === "recording" && ticket?.id == contactPresence.ticketId && (
          <div className={classes.messageLeft}>
            <div className={classes.wavebarsContainer}>
              <div className={clsx(classes.wavebars, classes.wavebar1)}></div>
              <div className={clsx(classes.wavebars, classes.wavebar2)}></div>
              <div className={clsx(classes.wavebars, classes.wavebar3)}></div>
              <div className={clsx(classes.wavebars, classes.wavebar4)}></div>
              <div className={clsx(classes.wavebars, classes.wavebar5)}></div>
            </div>
          </div>
        )}
      </div>
      {(ticket?.channel == "facebook" || ticket?.channel == "instagram") && (
        <div
          style={{
            width: "100%",
            display: "flex",
            padding: "10px",
            alignItems: "center",
            backgroundColor: "#E1F3FB",
          }}
        >
          {ticket?.channel === "facebook" ? (
            <Facebook small />
          ) : (
            <Instagram small />
          )}

          <span>
            Você tem 24h para responder após receber uma mensagem, de acordo
            com as políticas do Facebook.
          </span>
        </div>
      )}

      {loading && (
        <div>
          <CircularProgress className={classes.circleLoading} />
        </div>
      )}

      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="xl"
        fullWidth
        fullScreen
        classes={{ paper: classes.dialogPaper }}
      >
        {/* Cabeçalho do Modal */}
        <div className={classes.dialogHeader}>
          <Typography variant="h6" className={classes.dialogTitle}>
            Visualizador de Imagens
          </Typography>
          <div className={classes.dialogHeaderActions}>
            {/* Botão para baixar a imagem */}
            <IconButton
              onClick={() => {
        
                let href = imageList[selectedImageIndex];

                handleDownload(href)

              }}
              className={classes.downloadButton}
              title="Baixar Imagem"
            >
              <CloudDownload />
            </IconButton>


            {/* Botão para fechar */}
            <IconButton
              onClick={handleCloseModal}
              className={classes.closeButton}
              title="Fechar"
            >
              <Close />
            </IconButton>
          </div>
        </div>

        <DialogContent className={classes.dialogContent}>
          {/* Botão para imagem anterior */}
          <IconButton
            onClick={() => navigateImage('prev')}
            className={clsx(classes.navigationButton, classes.prevButton)}
          >
            <ArrowBackIos />
          </IconButton>

          <div className={classes.imageContainer}>
            <img
              src={imageList[selectedImageIndex]}
              alt="imagem"
              onClick={(e) => handleImageClick(selectedImageIndex, e)}
              style={{
                maxHeight: '80vh',
                maxWidth: '100%',
                objectFit: 'contain',
                cursor: zoomed ? 'zoom-out' : 'zoom-in',
                transform: zoomed
                  ? `scale(2) translate(calc(-${zoomPosition.x}% + 50%), calc(-${zoomPosition.y}% + 50%))`
                  : 'none',
                transition: 'transform 0.3s ease',
              }}
            />
          </div>

          {/* Botão para próxima imagem */}
          <IconButton
            onClick={() => navigateImage('next')}
            className={clsx(classes.navigationButton, classes.nextButton)}
          >
            <ArrowForwardIos />
          </IconButton>

          {/* Thumbnails */}
          <div className={classes.thumbnailsContainer}>
            {imageList.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`thumbnail-${index}`}
                onClick={() => setSelectedImageIndex(index)}
                className={clsx(classes.thumbnail, {
                  [classes.selectedThumbnail]: selectedImageIndex === index,
                })}
              />
            ))}
          </div>
        </DialogContent>
      </Dialog>



    </div>
  );
};

export default MessagesList;